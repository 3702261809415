import React, { useEffect, useState } from "react";
import { Box, FormControlLabel, Switch, OutlinedInput, Typography } from "@mui/material";
import { useUserRole } from "../Recipe/UserRoleContext";
import { hasEditingPermissions } from "./Utils";
function SeedCore({ id, data, updateNodeData }) {

  const role = useUserRole();

  const [isRandom, setIsRandom] = useState(data.isRandom ?? false);
  const [seed, setSeed] = useState(data.seed ?? Math.floor(Math.random() * 1000000) + 1);
  const [tempSeed, setTempSeed] = useState(seed);

  useEffect(()=>{
    updateNodeData(id, { 
      result: {
        isRandom: isRandom,
        seed: seed,
      },
      isRandom: isRandom,
      seed: seed,
      output:{
        type: "seed",
        [data.handles.output[0]]: {
          isRandom: isRandom,
          seed: seed,
        },
      },
      
    });
  }, [seed, isRandom]);

  const handleSeedChange = (event) => {
    setTempSeed(event.target.value);
  };

  const commitSeed = () => {
    setSeed(tempSeed);
  };

  return (
    <Box sx={{
      width: '100%',
      pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <FormControlLabel 
        sx={ { ml:1, width:'fit-content' } }
        control={<Switch size="small" checked={isRandom} onChange={() => setIsRandom(!isRandom)} />} 
        label={isRandom ? 
            <Typography variant="caption" color="text.secondary">Random Seed</Typography> : 
            <Typography variant="caption" color="text.secondary">Fixed Seed</Typography>
        } 
      />
      <OutlinedInput
        value={tempSeed}
        size="small"
        disabled={isRandom}
        color="weavy_green_outline"
        inputProps={{ type: 'number', step: 1 }}
        onChange={handleSeedChange}
        onBlur={commitSeed}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                commitSeed();
                e.target.blur();
            }
            }}
            onFocus={(e) => e.target.select()}
            sx={{
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
            },
            '& input[type=number]': {
                MozAppearance: 'textfield'
            },
            '& input': {
                textDecoration: isRandom ? 'line-through' : 'none'
            },
        }}
      />
    </Box>
  );
}

export default SeedCore;