import React, { useCallback, useEffect, useState } from "react";
import { colorMap } from '../../colors';
import ModelBaseComponent from "./ModelBaseComponent";
import { DynamicNode2 } from './VisualNodes';

function ModelBaseNode({ id, recipeId, data ,updateNodeData, editable, setNodesTypes, createNewNodeFromParamExpose , deleteEdgeByTargetHandleId }) {

  const { handles } = data;
  const [selectedOutput, setSelectedOutput] = useState(data.selectedOutput || 0);

  const [validationError, setValidationError] = useState([]);


  const deleteAllResults = useCallback(() => {
    updateNodeData(id, {
      result: [],
      selectedOutput: 0,
      output: {},
    });
  }, [id, updateNodeData]);

  const deleteCurrentResult = useCallback(() => {
    const selected = selectedOutput;
    let newSelected = selected;
    const newResult = data.result.filter((_, index) => index !== selected);

    if (newResult.length === 0) {
      newSelected = 0; // No results left
    } else if (selected >= newResult.length) {
      newSelected = newResult.length -1; // Deleted the last item, select the previous
    }
    
    const formattedOutput = {};
    handles.output.forEach((elementName, index) => {
      if(newResult[newSelected + index]){
        const { params, input, ...rest } = newResult[newSelected + index];
        formattedOutput[elementName] = rest;
      }
      else formattedOutput[elementName] = undefined;
    });
    updateNodeData(id, {
      result: newResult,
      selectedOutput: newSelected,
      output: formattedOutput,
    });
    setSelectedOutput(newSelected);

  }, [data.result, id, selectedOutput, updateNodeData]);

  const goToSource = () => {
    if (data.source) {
      window.open(data.source, '_blank');
    }
  };

  const setOutput = useCallback((selected)=>{
    const formattedOutput = {};
    handles.output.forEach((elementName, index) => {
      if(data.result[selected + index]){
        const { params, input, ...rest } = data.result[selected + index];
        formattedOutput[elementName] = rest;
      }
      else formattedOutput[elementName] = undefined;
    });
    updateNodeData(id,{
      selectedOutput: selected,
      output:formattedOutput,
    });
  },[handles.output, data.result, updateNodeData, id]);

  useEffect(()=>{
    if(data.result){
      setOutput(selectedOutput);
    }
  },[selectedOutput]);
  
  const nodeMenu = [
    {
      name:'divider',
    },
    {
      name: 'Delete current generation',
      action: deleteCurrentResult,
      disabled: !data.result || data.result.length === 0 || data.isLocked ? true : false,
      shortcut: data.isLocked ? <i className="fa-solid fa-lock fa-sm" style={ { opacity:.6 } }></i> : '',
    },
    {
      name: 'Delete all generations',
      action: deleteAllResults,
      disabled: !data.result || data.result.length === 0 || data.isLocked ? true : false,
      shortcut: data.isLocked ? <i className="fa-solid fa-lock fa-sm" style={ { opacity:.6 } }></i> : '',
    },
    {
      name:'divider',
    },
    {
      name: 'Learn more about this model',
      action: goToSource,
      disabled: !data.source ? true : false,
    },
  ];

  return (
    <DynamicNode2 id={ id } recipeId={ recipeId } data={ data } className="model" handleColor={ colorMap.get(data.color) } additionalMenu={ nodeMenu } validationError={ validationError }>
      <ModelBaseComponent
        id={ id }
        data={ data }
        updateNodeData={ updateNodeData }
        selectedOutput={ selectedOutput }
        setSelectedOutput={ setSelectedOutput }
        setOutput={ setOutput }
        container="node"
        setValidationError={ setValidationError }
        editable={ editable }
        setNodesTypes={ setNodesTypes }
        createNewNodeFromParamExpose={ createNewNodeFromParamExpose }
        deleteEdgeByTargetHandleId={ deleteEdgeByTargetHandleId }
        recipeId={ recipeId }
      />
    </DynamicNode2>
  );
}

export default ModelBaseNode;
