export const I18N_KEYS = {
  GENERAL: {
    CANCEL: 'CANCEL',
    RENAME: 'RENAME',
  },
  PAYMENTS: {
    SUCCESS_MESSAGE: 'PAYMENTS_SUCCESS_MESSAGE',
  },
  NAVIGATION_DRAWER: {
    BUY_MORE: 'NAVIGATION_DRAWER_BUY_MORE',
  },
  DASHBOARD_BANNER: {
    MESSAGE: 'DASHBOARD_BANNER_MESSAGE',
    CTA: 'DASHBOARD_BANNER_CTA',
  },
  UPGRADE_MODAL: {
    TITLE: 'UPGRADE_MODEL_TITLE',
    CTA: 'UPGRADE_MODEL_CTA',
  },
  MODEL_NODE: {
    ERROR_REQUIRED: 'RUN_MODEL_REQUIRED_ERROR',
    ERROR_IMAGE_INPUT: 'RUN_MODEL_CANNOT_GET_IMAGE_DIMENSIONS',
  },
  UPLOAD_LORA_NODE: {
    UPLOAD_BUTTON_TEXT: 'UPLOAD_LORA_NODE_UPLOAD_BUTTON_TEXT',
    UPLOADING: 'UPLOAD_LORA_NODE_UPLOADING',
  },
  RECIPE_MAIN: {
    ERROR_SAVE: 'ERROR_SAVING_RECIPE',
    FLOW: {
      GALLERY:{
        PARAMS: {
          SHOW_INFO: 'SHOW_INFO',
          HIDE_INFO: 'HIDE_INFO',
        },
      },
      MODEL_PROPERTIES: {
        SET_AS_PARAMETER: 'SET_AS_PARAMETER',
        SET_AS_INPUT: 'SET_AS_INPUT',
      },
    },
    NODES: {
      NODE_MENU: {
        DUPLICATE: 'DUPLICATE',
        DELETE: 'DELETE',
        LOCK: 'LOCK',
        UNLOCK: 'UNLOCK',
        RENAME: 'RENAME',
      },
      LOCKED: {
        THIS_NODE_IS_LOCKED: 'THIS_NODE_IS_LOCKED',
        CLICK_TO_UNLOCK: 'CLICK_TO_UNLOCK',
      },
    },
  },
  SHARED_DESIGN_APP: {
    MODAL:{
      DEFAULT_ERROR: 'SHARED_DESIGN_APP_MODAL_DEFAULT_ERROR',
      TITLE: 'SHARED_DESIGN_APP_MODAL_TITLE',
      LINK_COPIED: 'SHARED_DESIGN_APP_MODAL_LINK_COPIED',
      BUTTON_CTA: 'SHARED_DESIGN_APP_MODAL_BUTTON_CTA',
    },
    ACTIVATION_PAGE:{
      DEFAULT_ERROR: 'SHARED_DESIGN_APP_ACTIVATION_PAGE_DEFAULT_ERROR',
      TITLE: 'SHARED_DESIGN_APP_ACTIVATION_PAGE_TITLE',
    },
  },
  FLOW_NAVBAR: {
    SHARED_DESIGN_APP_BUTTON: 'FLOW_NAVBAR_SHARED_DESIGN_APP_BUTTON',
  },
  MAIN_DASHBOARD: {
    DESIGN_APPS: {
      TITLE: 'MAIN_DASHBOARD_DESIGN_APPS_TITLE',
    },
  },
};

export default I18N_KEYS;
