import React, { useState, useEffect, useCallback } from "react";
import { Box, Button } from "@mui/material";
import { DynamicNode2 } from "../VisualNodes";
import { colorMap } from "../../../colors";
import { useUserRole } from "../../Recipe/UserRoleContext";
import { hasEditingPermissions } from "../Utils";
function EditNode ({ id, data, updateNodeData, openEditWindow }) {

  const role = useUserRole();

  const { input, handles } = data;
  const [fileSrc, setFileSrc] = useState();
  const [fileOutput, setFileOutput] = useState(data.result || undefined);

  const handleDisconnection = useCallback(()=>{
    setFileOutput();
    updateNodeData(id, {
      result:{},
      output:{
        [data.handles.output[0]]:null,
      },
    });
  },[fileSrc]);

  //handle connection / disconnection
  useEffect(()=> {
    if(input && input[handles.input[0]]){
      setFileSrc(input[handles.input[0]]);
      const formattedOutput = {};
      // const output = { 
      //   type:'image',
      //   url:input[handles.input[0]].url,
      //   publicId:input[handles.input[0]].publicId
      // };
      formattedOutput[handles.output[0]] = input[handles.input[0]];
      updateNodeData(id,{ result: fileOutput, output:formattedOutput });
    }
    else {
      setFileSrc();
      handleDisconnection();
    }
  },[input]);
  
  useEffect(() => {
    if(fileOutput && Object.keys(fileOutput).length > 0){
      const formattedOutput = {};
      // console.log(fileOutput);
      // remove search params from the url due to Bria register issue 
      const urlObj = new URL(fileOutput?.originalUrl || fileOutput?.original ); // 9.8.24 fileOutput?.original for backward compatability before expsing and renaming file properties
      urlObj.search = "";
      const cleanUrl = urlObj.toString();
      const output = {
      //  type:'image', 
        url:cleanUrl,
        publicId: fileOutput.publicId,
        type: fileOutput.type,
        width: fileOutput.width,
        height: fileOutput.height,
        visualId: fileOutput.visualId,
      };
      formattedOutput[handles.output[0]] = output;
      updateNodeData(id,{ result: fileOutput, output:formattedOutput });
    }
  },[fileOutput]);

  /// UI
  const handleOpenEditWindow = () => {
    openEditWindow(id, data, "photopea" ,setFileOutput);
  };

    
  return (
    <DynamicNode2 id={ id } data={ data } className="edit"  handleColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      {fileSrc && <>
        <Box sx={ { mt:0 } } className="media-container">
          <img src={ fileOutput?.thumbnailUrl  || fileSrc?.url || "" } draggable="false" width="100%" style={ { display:'block' } } />
            
          {/* <canvas id="histogram" width="256" height="100"></canvas> */}
            
        </Box>
        <Button disabled={ !hasEditingPermissions(role, data) } onClick={ handleOpenEditWindow } variant="contained" color="weavy_cta" fullWidth sx={ { mt:1 } }>
          Edit
        </Button>
      </>
      }
    </DynamicNode2>
  );
}

export default EditNode;