import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./AuthContext";
import './language';
import { ErrorBoundary } from './components/ErrorBoundary';
import { MobileCheck } from './components/MobileCheck';

const options = {
  api_host: "https://eu.i.posthog.com",
};

const mobileCheckExcludedPaths = ['/early', '/'];

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <ErrorBoundary>
    <Router>
      <PostHogProvider
        apiKey={ 'phc_NT2sOH8m82boUw21Q1Vsr0tQUtjpRJUIE5aoqA0En8T' }
        options={ options }
      >
        <MobileCheck excludedPaths={ mobileCheckExcludedPaths }>
          <AuthProvider>
            <App />
          </AuthProvider>
        </MobileCheck>
      </PostHogProvider>
    </Router>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
