import { createContext, useContext } from 'react';

const NodeActionsContext = createContext({
  deleteNode: () => {},
  duplicateNode: () => {},
  toggleLockNode: () => {},
  openOverlayDialog: () => {},
  closeOverlayDialog: () => {},
});

export const useNodeActions = () => useContext(NodeActionsContext);

export const NodeActionsProvider = ({ children, deleteNode, duplicateNode, toggleLockNode, openOverlayDialog, closeOverlayDialog }) => (
  <NodeActionsContext.Provider value={{ deleteNode, duplicateNode, toggleLockNode, openOverlayDialog, closeOverlayDialog }}>
    {children}
  </NodeActionsContext.Provider>
);