import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, TextField, FormControlLabel, Checkbox, InputAdornment, IconButton } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useUserRole } from "../Recipe/UserRoleContext";
import { Resize, convertBase64ToUrl } from "./Cloudinary";
import { getFileDimensions, hasEditingPermissions } from "./Utils";

const MIN_SIZE = 50;
const MAX_SIZE = 5000;
const STEP = 1;

function ResizeCore (props) {

  // const updateNodeInternals = useUpdateNodeInternals();

  const role = useUserRole();

  const { id, data, updateNodeData, mediaContainerRef } = props;
  const { handles } = data;

  const [fileSrc, setFileSrc] = useState();
  const [fileRsz, setFileRsz] = useState();
    
  const [fileSize, setFileSize] = useState(data.resize_properties?.resize ?? { width: 512, height: 512 });
    
  const [fileType, setfileType] = useState();
  const [aspectRatio, setAspectRatio] = useState(data.resize_properties?.aspect_ratio?? 1); // Initial aspect ratio
  const [lockAspectRatio, setLockAspectRatio] = useState(data.resize_properties?.lock_aspect_ratio ?? true);
    
  const [tempWidth, setTempWidth] = useState(data.resize_properties?.resize?.width || 512);
  const [tempHeight, setTempHeight] = useState(data.resize_properties?.resize?.height || 512);
  const [isNewNode, setIsNewNode] = useState(data.resize_properties?  false: true);
  const [isFocused, setIsFocused] = useState(false);

  //// base 64 conversion
  const [isUploading, setIsUploading] = useState(false);
  const [base64Input, setBase64Input] = useState(data.resize_properties?.isSrcBase64 || false);
  const base64AlreadyConverted = useRef();
  const [uploadBase64Progress, setUploadBase64Progress] = useState(0);

  useEffect(()=>{
    if(data?.result?.convertedSrc){
      base64AlreadyConverted.value = true;
    }
    else {
      base64AlreadyConverted.value = false;
    }
  },[]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const prevInput = usePrevious(data.input);

  //// UPDATE PARENT NODE
  //    useEffect(()=>{
  //     setTempWidth(data?.result?.resize_properties?.resize.width);
  //     setTempHeight(data?.result?.resize_properties?.resize.height);
  //   },[data?.result])

  const exportResizedFile = useCallback(() => {

    if(!fileRsz) return;

    const outputObj = {};
    // console.log("FileRsz ", fileRsz);

    outputObj[handles.output[0]] = {
      url: fileRsz,
      type: fileSrc.type || fileRsz.type,
      publicId: fileSrc?.publicId || fileRsz.publicId,
      width: fileSize.width,
      height: fileSize.height,
      transformations: fileSrc.transformations? fileSrc.transformations : [],
    };

    if(outputObj[data.handles.output[0]].transformations) {
      const previousTransformations = outputObj[data.handles.output[0]].transformations;
      const newTransformation =  {
        nodeIdentifier:id,
        resize:{
          type:'scale',
          width:fileSize.width,
          height:fileSize.height,
        },
      };
      const index = previousTransformations.findIndex((t) => t.nodeIdentifier === id);
      if (index !== -1) {
        // If found, update the existing transformation
        previousTransformations[index] = newTransformation;
      } else {
        // Otherwise, add the new transformation
        previousTransformations.push(newTransformation);
      }
      outputObj[data.handles.output[0]].transformations = previousTransformations;
    }

    const result = {};

    if(base64Input){
      result.convertedSrc = fileSrc;
    }


    updateNodeData(id, {
      resize_properties: {
        resize: fileSize,
        lock_aspect_ratio: lockAspectRatio,
        aspect_ratio:aspectRatio,
        isSrcBase64: base64Input,
      },
      result,
      output:outputObj,
    });

  }, [fileRsz,fileSrc, updateNodeData, lockAspectRatio, aspectRatio, base64Input]);

  useEffect(()=>{
    if(fileSrc){ // this is for the initial loading - if fileRsz exists already
      exportResizedFile();
    }
  },[fileRsz]);




  /// MAIN RESIZE CALLBACK FUCNTION
    
  const resizeImage = (file, width, height) => {
    const rsz = Resize(file, width, height, id);
    setFileRsz(rsz);
  };

  // handle user changes in textfields
  useEffect(() => {
    if (data.input && data.input[handles.input[0]] && fileSrc) {
      if(fileSize.width && fileSize.height)
        resizeImage(fileSrc, fileSize.width, fileSize.height);
    }
  }, [fileSize, fileSrc]);


  /// handles connect and disconnect of edge
  useEffect(() => {

    const getFileDimensionFunc = async (url, type) => {
      const res = await getFileDimensions(url, type);
      setFileSize(res);
      setTempHeight(res.height);
      setTempWidth(res.width);
      setAspectRatio(res.width/res.height);
    };

    const handleNewConnectionOrInputChange = async () => {
 
           
      if(JSON.stringify(data.input) === JSON.stringify(prevInput)){
        return;
      }
      else {
        base64AlreadyConverted.value = false;
      }

      let inputFile = data.input[handles.input[0]];
      if (!inputFile.type || !inputFile.url) return;

      if (inputFile?.url.includes("data:image/png;base64") && !base64AlreadyConverted.value){
        setBase64Input(true);
        setIsUploading(true);
        base64AlreadyConverted.value = false;
        try {
          inputFile = await convertBase64ToUrl(inputFile.url, setUploadBase64Progress);
        } catch (error) {
          console.error("Error converting base64 to url: ", error);
          setIsUploading(false);
          setUploadBase64Progress(0);
        }
      }
      else if(base64AlreadyConverted.value){
        inputFile = data.result.convertedSrc;
      }
            
      setfileType(inputFile.type);
      setFileSrc(inputFile);
      if(isNewNode && (prevInput === undefined || prevInput.image === null)){ /// this should run only for new connection, so if prevInput was not null before, this means the change is in the input, hence do not change the resiz properties
        getFileDimensionFunc(inputFile.url, inputFile.type);
      }
    };

    if (data.input && data.input[handles.input[0]]) {
      handleNewConnectionOrInputChange();
    }
    else {
      // handle disconnect.
      setTempHeight(512);
      setTempWidth(512);
      setFileSize({ width:512, height:512 });
      setAspectRatio(1);
      setFileSrc();
      setFileRsz(null);
      setfileType();
      setIsNewNode(true);
      setBase64Input(false);
      base64AlreadyConverted.value = false;
      /// update other nodes on the disconnection
            
      updateNodeData(id, {
        resize_properties: {},
        result: {},
        output:
                {
                  [handles.output[0]]:null,
                },
      });
    }
  }, [data.input]);


  //// TEXTFEILDS CHANGES HANDLING
  const handleSizeUpdate = (dimension, value) => {
    let newSize = parseInt(value) || MIN_SIZE;
    newSize = Math.max(MIN_SIZE, Math.min(MAX_SIZE, newSize));

    /// prevent from resize if no change (could happen on blur)
    if(newSize === fileSize[dimension])
      return;

    // Optionally, adjust aspect ratio if needed
    if (lockAspectRatio) {
      if (dimension === "width") {
        setTempHeight(Math.round(newSize / aspectRatio));
        setFileSize(() => ({
          width: newSize,
          height: Math.round(newSize / aspectRatio),
        }));
      } else if (dimension === "height") {
        setTempWidth(Math.round(newSize * aspectRatio));
        setFileSize(() => ({
          width: Math.round(newSize * aspectRatio),
          height: newSize,
        }));
      }
    }
    else setFileSize((prevSize) => ({ ...prevSize, [dimension]: newSize }));
  };
  const imageLoaded = () => {
    setIsUploading(false);
    setUploadBase64Progress(0);
  };
 
  return (
    <>
      { isUploading &&
            // <Box sx={{width:'100%', mx:-1, mt:-1.1}}>
            <Box sx={ { width:'100%', position:'absolute', top:'34px', left:0 } }>
              <Box sx={ { width:'100%' } }>
                <LinearProgress
                  variant="determinate"
                  value={ uploadBase64Progress }
                  sx={ { mr:-2 } }
                  // sx={{color:color.Yambo_Text_On_Dark}}
                />
              </Box>
            </Box>
      }

      <Box>
           
        {fileRsz && <>
          <Box id='resize-node-container'
            sx={ {
              display: "flex",
              // gap:2,
              // mt:1.5,
              alignItems:'center',
              cursor: !hasEditingPermissions(role, data) ? 'default' : '',
              pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
            } }
          >
            <TextField
              aria-label="Width"
              type="number"
              name="width"
              variant="outlined"
              size="small"
              value={ tempWidth }
              onChange={ (e) => setTempWidth(e.target.value) }
              onKeyDown={ (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission if within a form
                  handleSizeUpdate('width', tempWidth);
                  e.target.blur();
                }
              } }
              onBlur={ () => {handleSizeUpdate('width', tempWidth); setIsFocused(false);} }
              inputProps={ {
                min: MIN_SIZE,
                max: MAX_SIZE,
                step: STEP,
              } }
              className={ isFocused ? "nowheel nodrag nopan": "" }
              onFocus={ () => {setIsFocused(true);} }
              InputProps={ {
                startAdornment: <InputAdornment position="start" sx={{color:'red'}}>w</InputAdornment>,
              } }
              
            
            
            />
              <IconButton
              sx={ {
                width:'20px',
                height:'20px',
                py:.2,
                borderRadius:"4px",
                fontSize:'12px',
                mx:.4
              } }
              onClick={() => setLockAspectRatio((prev) => !prev)}
            >
            { lockAspectRatio ?
              (
                <i className="fa-light fa-lock fa-xs"></i>
              ):(
                <i className="fa-light fa-lock-open fa-xs"></i>
              )
            }
            </IconButton>
            <TextField
               aria-label='Height'
               type="number"
               name="height"
               variant="outlined"
               size="small"
               value={ tempHeight }
               onChange={ (e) => setTempHeight(e.target.value) }
               onKeyDown={ (e) => {
                 if (e.key === 'Enter') {
                   e.preventDefault();
                   handleSizeUpdate('height', tempHeight);
                   e.target.blur();
                 }
               } }
               onBlur={ () => { handleSizeUpdate('height', tempHeight); setIsFocused(false);} }
               inputProps={ {
                 min: MIN_SIZE,
                 max: MAX_SIZE,
                 step: STEP,
               } }
               className={ isFocused ? "nowheel nodrag nopan": "" }
               onFocus={ () => {setIsFocused(true);} }
               InputProps={ {
                 startAdornment: <InputAdornment position="start">h</InputAdornment>,
               } }
            />
          </Box>
      
     
          <Box ref={ mediaContainerRef } sx={ { mt:1 } }>
            {fileType === 'image' ? (
              <Box
                component="img"
                draggable="false"
                onLoad={ (e) => {
                  imageLoaded(e.target);
                } }
                src={ fileRsz }
                alt=""
                sx={ {
                  width: '100%',
                  minWidth: '100px',
                  display: "block",
                } }
              />
            ) : fileType === 'video' ? (
              <video crossOrigin="anonymous" draggable="false" src={ fileRsz } style={ { width: '100%' } } controls loop />
            ) : (
              <Box>Unsupported file</Box>
            )}
          </Box>
        </>}
        
      </Box>
    </>
  );
}

export default ResizeCore;