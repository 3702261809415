import { colorMap } from "../../colors";
import { DynamicNode2 } from "./VisualNodes";

function OutputNode({ id, data, updateNodeData }) {


  return (
    <>
      <DynamicNode2 
      id={ id } 
      data={ data } 
      hideBody={true} 
      inputHandleYPos="50%" 
      className="output" 
      handleColor={ colorMap.get(data.color) } 
      headerColor={ colorMap.get(data.dark_color) }
      icon={<i className="fa-sharp fa-light fa-forward-step"></i>}
      >
      </DynamicNode2>
    </>

  );
}

export default OutputNode;