import React, { useState } from "react";
import {
  Box,
  Dialog,
  Button,
  TextField,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import I18N_KEYS from "../../language/keys";

function RenameNodeDialog ({ dialogData, updateNodeData, closeOverlayDialog }) {

  const { t: translate } = useTranslation();

  const { id, data } = dialogData;
  const [nodeName, setNodeName] = useState(data.name || '');
 

  const handleNodeNameChange = (e) => {
    setNodeName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateNodeData(id, {
      name: nodeName,
    });
    closeOverlayDialog(); // Close the dialog
  };

  return(
    <Dialog onClose={ closeOverlayDialog } open={ true }>
      <Box onSubmit={ handleSubmit } component="form" id="custom-node-edit-details-dialog-content" sx={ { width:{ xs:'90%', sm:'400px' }, display:'flex', flexDirection:'column', p:2 } }>
        <TextField value={ nodeName } onChange={ handleNodeNameChange } required label="" size="small" />
        <Box sx={ { display:'flex', flexDirection:'row', justifyContent:'space-between', mt:2 } }>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="weavy_cta_secondary"
            onClick={ closeOverlayDialog }
            sx={ { mr:2 } }
          >
            { translate(I18N_KEYS.GENERAL.CANCEL) }
          </Button>
          <Button
            type="submit"
            fullWidth 
            variant="contained"
            color="weavy_cta_secondary"
          >
            { translate(I18N_KEYS.GENERAL.RENAME) }
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default RenameNodeDialog;