import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import cv from "@techstark/opencv-js";
import { DynamicNode2 } from "../VisualNodes";
import { colorMap } from "../../../colors";
import { useUserRole } from "../../Recipe/UserRoleContext";
import { hasEditingPermissions } from "../Utils";

const channelsMap = {
  "Red": 1,
  "Green": 2,
  "Blue": 3,
};

function ChannelsNode ({ id, data, updateNodeData }) {

  const role = useUserRole();

  const { input, handles } = data;
  const [fileSrc, setFileSrc] = useState();
  const [fileOutput, setFileOutput] = useState();
  const [selectedChannel, setSelectedChannel] = useState(data.channel || channelsMap["Red"]);

  const channelSelectRef = useRef(null);
  const canvasRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);


  const handleDisconnection = useCallback(()=>{
    if(canvasRef.current){
      const canvas = canvasRef.current;
      canvas.width = 240;
      canvas.height = 80;
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
    setFileOutput();
    updateNodeData(id, {
      result:{},
      output:{
        [data.handles.output[0]]:null,
      },
    });
  },[fileSrc]);

  //handle connection / disconnection
  useEffect(()=> {
    if(input && input[handles.input[0]]){
      setFileSrc(input[handles.input[0]]);
    }
    else {
      setFileSrc();
      handleDisconnection();
    }
  },[input]);

  const extract = (imageUrl, channel) => {
    return new Promise((resolve, reject) => {
      const imgElement = document.createElement('img');
      imgElement.src = imageUrl;
      imgElement.crossOrigin = 'Anonymous';
      imgElement.onload = () => {
        try {
          const mat = cv.imread(imgElement);
          const channels = new cv.MatVector();
          cv.split(mat, channels);

          let selectedChannelMat;
          switch (channel) {
            case 1:
              selectedChannelMat = channels.get(0);
              break;
            case 2:
              selectedChannelMat = channels.get(1);
              break;
            case 3:
              selectedChannelMat = channels.get(2);
              break;
          }
          let normalized = new cv.Mat();
          cv.normalize(selectedChannelMat, normalized, 0, 255, cv.NORM_MINMAX);
          cv.cvtColor(normalized, normalized, cv.COLOR_GRAY2RGB);
          // Output the processed image
          const canvas = document.createElement('canvas');
          canvas.width = normalized.cols;
          canvas.height = normalized.rows;
          cv.imshow(canvas, normalized);

          const alphaImageUrl = canvas.toDataURL('image/png');
          normalized.delete();
          mat.delete();
          // mv.delete();
          // merged.delete();
          // zeroMat.delete();
          // r.delete();
          // g.delete();
          // b.delete();
          resolve(alphaImageUrl);
        } catch (error) {
          reject(error);
        }
      };
      imgElement.onerror = reject;
    });
  };

  useEffect(()=>{
    const extractAlpha = async (url, channel)=>{
      try {
        const alpha = await extract(url, channel);
        
        setFileOutput((prev) => ({ ...prev, url: alpha }));
    
        updateNodeData(id, {
          result: { ...fileOutput, url: alpha },
          channel: channel,
          output: {
            [handles.output[0]]: {
              ...fileSrc,
              url: alpha,
            },
          },
        });
      } catch (error) {
        console.error("Error during channel extract:", error);
        // Handle error appropriately, perhaps resetting state or showing an error message
      }
    };

    if(fileSrc && fileSrc.url){
      extractAlpha(fileSrc.url, selectedChannel);
    }
  },[fileSrc, selectedChannel]);

  
  /// UI
  const handleChannelSelectChange = (e) => {
    setSelectedChannel(e.target.value);
    channelSelectRef.current.blur();
  };

    
  return (
    <DynamicNode2 id={ id } data={ data } className="channels"  handleColor={ colorMap.get(data.color) }>
      {fileSrc &&
          <Box
            id="channels-params-container"
            className={ isFocused ? "nowheel nodrag nopan": "" }
            onFocus={ () => {setIsFocused(true);} }
            onBlur={ () => {setIsFocused(false);} }
            sx={ {
              display:'flex',
              width:'100%',
              pointerEvents: !hasEditingPermissions(role, data) ? "none":"",
            } }
          >
              
            <Box id="blur-type-container" sx={ { mb:1, width:'100%' } }>
              <Select
                ref={ channelSelectRef }
                fullWidth
                size="small"
                labelId="blur-type-label"
                id="blur-type-select"
                value={ selectedChannel }
                label="Type"
                onChange={ handleChannelSelectChange }
                onClose={ ()=>{
                  setTimeout(() => {
                    document.activeElement.blur();
                  }, 0);
                  setIsFocused(false);
                } }
              >
                {Object.keys(channelsMap).map((color) =>
                  (
                    <MenuItem key={ color } value={ channelsMap[color] }>{color}</MenuItem>
                  ),
                )}
              </Select>
            </Box>
          </Box>}
      <img src={ fileOutput? fileOutput.url:"" } draggable="false" width="100%" />
    </DynamicNode2>
  );
}

export default ChannelsNode;