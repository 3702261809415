import lodash from "lodash";
import axiosInstance from "../../services/axiosConfig";
import { ROUTES } from "../../consts/routes.consts";
import { ModelType } from "../../enums/model-type.enum";
import { getImageDimensions, registerImageUtil, negateImageColors } from "./Utils";
import { SchedluerMapFix } from "./Civit/civit-scheduler-mapping";

type Handle = {
  required?: boolean,
  type?: string,

}

interface PredictionStatus {
  status: 'starting' | 'processing' | 'initial_processing' | 'succeeded' | 'failed' | 'canceled';
  progress?: number;
  results?: any;
  remainingCredits?: number;
  error?: any;
}

interface PollCallbacks {
  onProgress?: (progress: number) => void;
  onSuccess?: (results: any[], remainingCredits?: number) => void;
  onError?: (error: any) => void;
  onStatusChange?: (status: string) => void;
}

export const pollPredictionStatus = async (
  predictionId: string,
  callbacks: PollCallbacks,
  isCanceled: () => boolean = () => false,
): Promise<void> => {
  const startTime = Date.now();

  const poll = async () => {
    if (isCanceled()) {
      return;
    }

    try {
      const response = await axiosInstance.get<PredictionStatus>(
        `/v1/models/predict/${predictionId}/status`,
      );

      let status = response.data.status;
      if (status === "processing" && !response.data.progress) {
        status = 'initial_processing';
      }

      callbacks.onStatusChange?.(status);

      if (isCanceled()) {
        return;
      }

      switch (status) {
        case "succeeded":
          callbacks.onSuccess?.(
            response.data.results,
            response.data.remainingCredits,
          );
          
          return; // Exit polling

        case "processing":
          callbacks.onProgress?.(response.data.progress || 0);
          break;

        case "failed":
          callbacks.onError?.(response.data.error);
          
          return; // Exit polling

        case "canceled":
          return; // Exit polling
      }

      // Calculate next polling interval
      const elapsedTime = Date.now() - startTime;
      let interval = 1000; // Default 1s
      if (elapsedTime < 10000) {
        interval = 1000;
      } else if (elapsedTime < 30000) {
        interval = 2500;
      } else {
        interval = 5000;
      }

      // Schedule next poll
      setTimeout(poll, interval);
    } catch (error: any) {
      callbacks.onError?.(error?.message);
    }
  };

  // Start polling
  await poll();
};

// todo: improve
const getModelType = (model: Model): ModelType => {
  if (model.service === ModelType.Replicate){
    return ModelType.Replicate;
  }

  if (model.service === ModelType.Civit){
    return ModelType.Civit;
  }

  const enumValues = Object.values(ModelType) as string[];
  if (enumValues.includes(model.name)){
    return model.name as ModelType;
  }

  return ModelType.Replicate;
};

//we saved a wrong enum values in the db. this function fix it. remove after migrating the db data.
const fixCivitScheduler = (params) => {
  if(params.scheduler){
    params.scheduler = SchedluerMapFix.get(params.scheduler) || params.scheduler;
  }

  return params ;
};

export const runModel = async (
  handles,
  input,
  model,
  params,
  nodeId: string,
  recipeId: string,
  callbacks: PollCallbacks = {},
  seed?,
  version?,
): Promise<RunModelResponse> => {
  const isValid = true; // todo: replace with input validation
  if(!isValid) {
    // error
  }
  let dimensions: any = {}; // todo: typing
  let inputObject: any = {}; // todo: typing
  // iterate over the input handles and create the input object to be sent to the server
  // if one of the inputs is an image, we extract the image dimensions and use is as params for the model
  if (version === 2) {
    // Handle the case where handles.input is a map
    Object.entries(handles.input as Record<string, Handle>).forEach(([key, handle]) => {
      if (input[key] !== undefined && input[key] !== "" && input[key] !== null) {
        if (input[key]?.type === "image" || input[key]?.type === "video" || input[key]?.type === "audio") {
          inputObject[key] = input[key].url;
        } else if (input[key]?.type === "text" && "value" in input[key]) {
          inputObject[key] = input[key].value;
        } else if ((input[key]?.type === "number" || input[key]?.type === "integer") && "value" in input[key]) {
          inputObject[key] = input[key].value;
        } else {
          inputObject[key] = input[key];
        }
      }
    });
  } else {
    // backwards compatibility - Handle the case where handles.input is an array
    handles.input.forEach((handle) => {
      if (input[handle] !== undefined && input[handle] !== "" && input[handle] !== null) {
        if (input[handle]?.type === "image" || input[handle]?.type === "video" || input[handle]?.type === "audio") {
          inputObject[handle] = input[handle].url;
        } else if (input[handle]?.type === "text" && "value" in input[handle]) {
          inputObject[handle] = input[handle].value;
        } else {
          inputObject[handle] = input[handle];
        }
      }
    });
  }
  if(input.image?.url){
    try {
      dimensions = await getImageDimensions(input.image.url);
    } catch (error) {
      console.error('Cannot read input image');
      throw new Error("Cannot read input image");
    }
  }

  const modelType = getModelType(model);

  // todo: move all of those outside if this function
  /// handle midjourney model
  if(modelType === ModelType.Midjourney){
    // handle backward compatability - needed?
    if (inputObject.text?.[0].prompt) {
      inputObject.prompt = inputObject.text[0].prompt;
    } else if (inputObject?.text) {
      inputObject.prompt = inputObject.text;
    } else if (inputObject.prompt?.[0].prompt) {
      inputObject.prompt = inputObject.prompt[0].prompt;
    }

    if(inputObject.negative_prompt && inputObject.negative_prompt.length > 0){
      inputObject = {
        ...inputObject,
        prompt: `${inputObject.prompt} --no ${inputObject.negative_prompt}`,
      };
    }
    if(inputObject.image){
      inputObject = {
        ...inputObject,
        prompt: `${inputObject.image} ${inputObject.prompt}`,
      };
    }
    if(inputObject.style_image){
      inputObject = {
        ...inputObject,
        prompt: `${inputObject.prompt} --sref ${inputObject.style_image}`,
      };
    }
    // let mj_prompt;
    if(params){
      inputObject.prompt = `${inputObject.prompt} --ar ${params.aspect_ratio} --iw ${params.image_weight} ${params.tile? "--tile":""}`;
    }
  }

  if(modelType === ModelType.BRRemoveObject || modelType === ModelType.BRPsd){
    let visualId;
    if(input["image"].visualId){
      visualId = input["image"].visualId;
    } else { // backward compatibility
      visualId = await registerImageUtil(input["image"].url);
    }
    inputObject = {
      ...inputObject,
      visualId,
    };
  }

  if (modelType === ModelType.SDControlnet) {
    inputObject = {
      ...inputObject,
      type: params?.control_type,
    };
  }

  if(model.name === "ideogram-ai/ideogram-v2" && input.mask.url){
    inputObject.mask = await negateImageColors(input.mask.url);
  }

  // todo: check all the text to prompt and make sure no discrepancy
  if(modelType === ModelType.Civit){
    params = fixCivitScheduler(params);
  }

  let cleanParams;
  if(params){
    cleanParams = lodash.cloneDeep(params);
    Object.entries(params).forEach(([key, value]) => {
      if(Array.isArray(value)){
        cleanParams[key] = value.filter(Boolean);
      }

      if (value === "" || value === null) {
        cleanParams[key] = undefined;
      }
    });
  }

  if(seed){
    if(cleanParams.seed)
      cleanParams.seed = seed;
  }
  // console.log(cleanParams);

  const body: any = { // todo: typing
    model: {
      ...model,
      type: modelType,
    },
    input: {
      ...cleanParams,
      ...inputObject,
      ...(dimensions.width && dimensions.height ?
        { width: dimensions.width, height: dimensions.height } :
        {}),
    },
    nodeId,
    recipeId,
  };

  const response = await axiosInstance.post(ROUTES.RunModel, body,{ "axios-retry": { retries: 0 } });

  const predictionId = response.data.predictionId;

  // Start polling in the background
  pollPredictionStatus(
    predictionId,
    callbacks,
  );

  return response.data;
};

export const runComfyWorkflow = async (
  workflowJson,
  model,
  params,
  seed,
  credits,
) => {

  const isValid = true; // todo: replace with input validation
  if(isValid){
    let cleanParams;
    if(params){
      cleanParams = lodash.cloneDeep(params);
      Object.entries(params).forEach(([key, value]) => {
        if (!value) {
          cleanParams[key] = undefined;
        }
      });
    }

    if(seed){
      if(cleanParams.seed)
        cleanParams.seed = seed;
    }
    // console.log(cleanParams);



    const body = {
      model: model,
      input: {
        ...cleanParams,
        workflow_json: JSON.stringify(workflowJson),
        type: ModelType.Replicate,
      },
    };

    // if(credits !== "unlimited" && credits < 10){
    //   throw new Error("Insufficient credits");
    // }
    const response = await axiosInstance.post(ROUTES.RunModel, body,{ "axios-retry": { retries: 0 } });

    return response.data.predictionId;
  }
};

export const runModelFromEditor = async () => {};
