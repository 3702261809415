import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/system';
import { color } from '../../../colors';
import ThreeDeeViewer from '../../Nodes/ThreeDeeViewer';
import GalleryParams from './Gallery/GalleryParams';

export const NonEditableTextarea = styled(InputBase)({
  // pointerEvents: 'none',
  userSelect: 'text',
  cursor: 'text',
  border: `1px solid ${color.Yambo_Purple_Stroke}`,
  borderRadius: '4px',
  padding:'8px',
});

const MAX_IMAGES_PER_ROW = 20;
function NodeImageList ({ images, selected, setSelected, container, disabled, threeDProps }) {

  const { 
    cameraPosition = null, 
    setCameraPosition = () => {}, 
    is3DLocked = false, 
    setIs3DLocked = () => {}, 
    setExported3DImage = () => {} 
  } = threeDProps || {};

  const maxSteps = images.length;
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const [scale, setScale] = useState(1);
  const imgRef = useRef(null);
  const resetTransformRef = useRef(null);
  const [transformKey, setTransformKey] = useState(0);

  const imagesPerRow = Math.min(images.length, MAX_IMAGES_PER_ROW);
  const rows = Math.ceil(images.length / imagesPerRow);
  const isSingleRow = images.length <= imagesPerRow;

  const resetZoom = async () => {
    if (resetTransformRef.current) {
      // console.log("reseting zoom");
      await resetTransformRef.current.resetTransform();
      setScale(1);
    }
  };

  useEffect(()=>{
    if (imgRef.current && images.length > 0) {
      const renderedWidth = imgRef.current.offsetWidth;
      const initialZoom = (renderedWidth / images[selected].width) * 100;
      setZoomPercentage(Math.round(initialZoom));
    }
  },[]);

  const calculateZoomPercentage = useCallback((newScale) => {
    if (imgRef.current && images.length > 0) {
      const renderedWidth = imgRef.current.offsetWidth * newScale;
      const zoomPercent = (renderedWidth / images[selected].width) * 100;
      setZoomPercentage(Math.round(zoomPercent));
    }
  }, [images, selected]);

  const handleNext = async () => {
    if(disabled) return;
    if(container === 'gallery') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };
  
  const handleBack = async () => {
    if(disabled) return;
    if(container === 'gallery')
      await resetZoom();
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  const handleArrowUp = async () => {
    if(container === 'gallery') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      return prevActiveStep - imagesPerRow >= 0 ? prevActiveStep - imagesPerRow : prevActiveStep;
    });
  };

  const handleArrowDown = async () => {
    if(container === 'gallery') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      return prevActiveStep + imagesPerRow < maxSteps ? prevActiveStep + imagesPerRow : prevActiveStep;
    });
  };

  const handleImageLoad = () => {
    // Calculate the zoom percentage when the image is fully loaded
    if (imgRef.current && images.length > 0) {
      const initialZoom = (imgRef.current.offsetWidth / images[selected].width) * 100;
      setZoomPercentage(Math.round(initialZoom));
    }
  };

  useEffect(()=>{
    // calcualte the zoom percentage on zoom change
    if(scale){
      calculateZoomPercentage(scale);
    }
  },[scale]);

  useEffect(() => {
    // Reset the zoom scale when the selected image changes
    if (resetTransformRef.current) {
      resetTransformRef.current.resetTransform();
      setScale(1);
    }
  }, [selected]);

  useEffect(()=>{
    const handleKeydown = (event) => {
      if(event.key === 'ArrowRight'){
        event.stopPropagation();
        if(container === 'gallery')
          handleNext();
      }
      if(event.key === 'ArrowLeft'){
        event.stopPropagation();
        if(container === 'gallery')
          handleBack();
      }
      if(event.key === 'ArrowUp'){
        event.stopPropagation();
        if(container === 'gallery')
          handleArrowUp();
      }
      if(event.key === 'ArrowDown'){
        event.stopPropagation();
        if(container === 'gallery')
          handleArrowDown();
      }
    };
    window.addEventListener('keydown', handleKeydown);
    
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  },[]);

  return (
    <Box sx={ {
      display:'flex',
      flexDirection:'column',
      width:'100%',
      height:'100%',
      position:'relative',
    } }
    >
      <Box
        id='image-list-container'
        sx={ {
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          height: maxSteps > 1 && container === 'gallery' ? '85%':'100%',
          width:'100%',
          mb: container === 'node' && images[selected]?.type !== 'text' ? 1 : 0,
        } }
      >
        <Box
          id='image-list-wrapper'
          ref={ imgRef }
          sx={ {
            maxWidth: container === 'node' ? 400: 'none',
            width: container === 'node' ? 'auto':  '90%',
            height: container === 'node' ? 'auto': '85%',
            margin: 'auto',
          } }
          onClick={ (event)=> event.stopPropagation() }
        >
          {images.map((img, index) => (
            <Box key={ index } style={ { width:'100%', height:'100%', display: index === selected ? 'block' : 'none' } }>
              {img.type && img.type.includes('image') && <>
                {container === 'node' ?
                  ( <>
                    <img
                      src={ img.url }
                      draggable="false"
                      style={ { width: '100%', height:'100%', display:'block', position:'relative' } }
                    />
                    <Typography
                      variant="caption"
                      sx={ { fontWeight:'bold', fontSize:'10px', position:'absolute', top:5, left:5, textShadow:'0px 0px 2px black' } }
                    >
                      {img.width} X {img.height}
                    </Typography>
                  </>):
                  (
                    <>
                      <TransformWrapper
                        style={ { width:'100%', height:'100%' } }
                        centerZoomedOut={ true }
                        limitToBounds={ true }
                        minScale={ 1 }
                        pinch={ {
                          step:100, // Increase this value for more sensitivity
                        } }
                        onZoom={ (ref) => {
                          setScale(ref.state.scale);
                        } }
                        ref={ resetTransformRef }
                        key={ transformKey }
                      >
                        <TransformComponent style={ { width:'100%', height:'100%' } }>
                          <img
                            className="media-container"
                            onLoad={ handleImageLoad }
                            src={ img.url }
                            draggable="false"
                            style={ { maxWidth: '100%', maxHeight:'100%', display:'block', margin:'auto' } }
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    </>
                  )
                }
              
              </>
              }
              {img.type && img.type.includes('video') &&
                  <>
                    <video crossOrigin="anonymous" draggable="false" src={ img.url } controls loop style={ { width: '100%', height:'100%', display:'block', position:'relative' } } />
                    { container === 'node' &&
                        <Typography
                          variant="caption"
                          sx={ { fontWeight:'bold', fontSize:'10px', position:'absolute', top:5, left:5, textShadow:'0px 0px 2px black' } }
                        >{img.width} X {img.height}
                        </Typography>
                    }
                  </>
              }
              {index === selected && img.type && img.type.includes('3D') && ( // this is unique case - in order to completely re-render the 3D canvas (to solve canvas size issue)
                 <Box sx={ { width:'100%', height:'100%', position:'relative', display:'flex', justifyContent:'center'} }>
                 { container === 'node' && <Tooltip title={ is3DLocked ? "Unlock Camera" : "Lock Camera" }>
                   <IconButton 
                     onClick={ () => setIs3DLocked(!is3DLocked) } 
                     sx={ { 
                       position:'absolute', 
                       top:0, 
                       right:0, 
                       zIndex:1000, 
                       width:'40px', 
                       height:'40px',
                     } }
                   >
                     {is3DLocked ? 
                     <i className="fa-kit fa-light-camera-rotate-slash fa-xs"></i> : 
                     <i className="fa-light fa-camera-rotate fa-xs"></i>}
                      </IconButton>
                    </Tooltip>}
                    <ThreeDeeViewer 
                      key={ img.url } 
                      objUrl={ img.url } 
                      containerSize={ container === 'gallery'? { w: 600, h: 600 }:null } 
                      setExportedImage={ setExported3DImage || null } 
                      lockOrbit={ is3DLocked || false } 
                      cameraPosition={ cameraPosition || { x: -3, y: 4, z: 5 } }
                      setCameraPosition={ setCameraPosition || null }
                    />
                  </Box>
              )}
              {img.type && img.type.includes('audio') &&
                <Box
                  sx={ { height:'80px', width:'300px' } }
                >
                  <audio crossOrigin="anonymous" draggable="false" src={ img.url } controls loop style={ { width: '100%', display:'block' } } />
                </Box>
              }
              {img.type && img.type.includes('text') && <Box sx={ { background:color.Yambo_Purple_Dark } }>
                {/* <Typography variant='caption'>Result</Typography> */}
                <Box sx={ {
                  color:'white',
                  fontSize:'12px',
                  fontStyle:'italic',
                  width:'300px',
                  mb: maxSteps > 1 ? 0 : 1, // add some margins if no scrolling arrows
                } }
                >
                    
                  <NonEditableTextarea
                    fullWidth
                    value={ img.value }
                    multiline={ true }
                    readOnly={ true }
                  />
                </Box>
              </Box> }
            </Box>
          ))}
          {container === 'gallery' && (images[selected]?.type === 'image' || images[selected]?.type === 'video') &&
            <Box id='file-info-container'
              sx={ {
                position:'absolute',
                top:20,
                left:20,
                display:'flex',
                flexDirection:'column',
              } }
            >
              <Typography
                variant="caption"
                sx={ {
                  fontWeight:'bold',
                  fontSize:'10px',
                  textShadow:'0px 0px 2px black',
                } }
              >
                {images[selected].width} X {images[selected].height} {container === 'gallery' && images[selected].type === 'image'? `| ${zoomPercentage}%`:``}
              </Typography>
              {(images[selected].params || images[selected].input) &&
                  <Box sx={ { position:'relative' } }>
                    <GalleryParams inputs={ { ...(images[selected].params || {}),  ...(images[selected].input || {}) } } selected={ selected } />
                  </Box>
              }
            </Box>
          }
         
          {/* Node carousel */}
          {container === 'node' && !disabled && maxSteps > 1 && (
            <Box
              sx={ {
                position: images[selected]?.type !== 'text' ? 'absolute' : 'relative',
                bottom: images[selected]?.type !== 'text' ? 7: 0,
                my: images[selected]?.type !== 'text' ? 0 : .5,
                width:'100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: images[selected]?.type !== 'text' ? '#0008': '',
              } }
              onMouseEnter={ (e)=> e.stopPropagation() }
            >
              <Button size="small" onClick={ handleBack }>
                <KeyboardArrowLeft />
              </Button>
              <Typography variant="body2" color="common.white">
                {`${selected + 1}/${maxSteps}`}
              </Typography>
              <Button size="small" onClick={ handleNext }>
                <KeyboardArrowRight />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {container === "gallery" && maxSteps > 1 && (
        <Box
          sx={ {
            width:'100%',
            height:'15%',
       
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            pb:4,
          } }
        >
          <Box
            id='media-gallery-carousel'
            sx={ {
              width:'80%',
              height:'100%',
              margin:'auto',
              // position: 'relative',
              // bottom:0, 
            } }
            onClick={ (event)=> event.stopPropagation() }
          >
            {Array.from({ length: rows }).map((_, rowIndex) => (
              <Box
                key={ rowIndex }
                sx={ {
                  height: `${100 / rows}%`, // Distribute height evenly among rows
                  width: '100%',
                  display: 'flex',
                  justifyContent: isSingleRow ? 'center' : 'flex-start',
                } }
              >
                {images.slice(rowIndex * imagesPerRow, (rowIndex + 1) * imagesPerRow).map((file, index) => {
                  const globalIndex = rowIndex * imagesPerRow + index;
                  
                  return (
                    <Box
                      key={ globalIndex }
                      sx={ {
                        height:'100%',
                        width: `${100 / imagesPerRow}%`, // Distribute width evenly
                        border: selected === globalIndex ? `1px solid ${color.Yambo_Purple}` : 'none',
                      } }
                      onClick={ () => setSelected(globalIndex) }
                      id={ `carousel-item-${globalIndex}` }
                    >
            
                      {file.thumbnailUrl ? (
                        <img
                          src={ file.thumbnailUrl }
                          alt={ `Image ${globalIndex}` }
                          style={ {
                            display: 'block',
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            filter: selected !== globalIndex ? 'grayscale(100%) brightness(60%)' : 'none',
                            cursor:'pointer',
                          } }
                        />
                      ):(
                        <Box sx={ { height:'100%' ,display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' } }>
                          <Typography>Result {globalIndex + 1}</Typography>
                          <Typography variant='caption'>No Preview Available</Typography>
                        </Box>
                      )
                      }
                    </Box>
                  );
                })}
              </Box>))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NodeImageList;
