import { colorMap } from "../../colors";
import { DynamicNode2 } from "./VisualNodes";
import BooleanCore from "./BooleanCore";

function BooleanNode({ id, data, updateNodeData }) {
  return (
    <DynamicNode2 id={ id } data={ data } className="boolean"  handleColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      <BooleanCore id={ id } data={ data } updateNodeData={ updateNodeData } />
    </DynamicNode2>
  );
}

export default BooleanNode;