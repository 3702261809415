import React, { useEffect, useState } from "react";
import { Box, FormControl, RadioGroup, FormControlLabel, Radio, OutlinedInput, Typography, Link } from "@mui/material";
import { useUserRole } from "../../Recipe/UserRoleContext";
import { hasEditingPermissions } from "../Utils";

function NumberCore({ id, data, updateNodeData }) {

  const role = useUserRole();

  const [number, setNumber] = useState(isNaN(data.result) ? 1 : (data.result ?? 1));
  const [tempNumber, setTempNumber] = useState(number);
  const [min, setMin] = useState(data.min ?? "");
  const [max, setMax] = useState(data.max ?? "");
  const [step, setStep] = useState(data.step ?? 1);
  const [mode, setMode] = useState(data.mode ?? "integer");
  const [showMore, setShowMore] = useState(false);

  // modes: 
  // if "integer" then step is 1
  // if "number" then step is 0.1
  // has min / max the slider

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  useEffect(()=>{
    setStep(mode === 'integer' ? 1 : 0.1);
    if(mode === 'integer'){
      setNumber(parseInt(number));
    }
  }, [mode]);


  useEffect(()=>{
    updateNodeData(id, { 
      result:number,
      min,
      max,
      step,
      mode,
      output:{ 
        type: mode,
        [data.handles.output[0]]: number, 
      },
    });
  }, [number, min, max, step, mode]);

  const handleNumberChange = (event) => {
    setTempNumber(event.target.value);
  };

  const commitNumber = () => {
    let value = mode === 'integer' ? parseInt(tempNumber) : parseFloat(tempNumber);
    value = isNaN(value) ? 0 : value;
    
    // Check min bound if exists
    if (min !== "") {
      const minValue = mode === 'integer' ? parseInt(min) : parseFloat(min);
      if (value < minValue) {
        value = minValue;
      }
    }
    // Check max bound if exists 
    if (max !== "") {
      const maxValue = mode === 'integer' ? parseInt(max) : parseFloat(max);
      if (value > maxValue) {
        value = maxValue;
      }
    }

    setNumber(value);
    setTempNumber(value);
  };

  return (
    <Box sx={{
      width: '100%',
      pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <OutlinedInput
          fullWidth
          value={tempNumber}
          size="small"
          color="weavy_green_outline"
          inputProps={{ type: 'number', step: step }}
          onChange={handleNumberChange}
          onBlur={commitNumber}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              commitNumber();
              e.target.blur();
            }
          }}
          onFocus={(e) => e.target.select()}
          sx={{
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0
            },
            '& input[type=number]': {
              MozAppearance: 'textfield'
            }
          }}
        />
      </Box>
      {showMore && 
      <>
      <FormControl sx={{ }}>
        <RadioGroup
          row
          aria-labelledby="mode-radio-buttons-group-label"
          value={mode}
          name="mode-radio-buttons-group"
          onChange={handleModeChange}
        >
          <FormControlLabel 
          value="integer" 
          control={
            <Radio 
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 14,
              },
              color: 'var(--Yambo_Green_Stroke)',
              '&.Mui-checked': {
                  color: 'var(--Yambo_Green_Stroke)',
                },
              }}
            />} 
          label="Integer" 
          />
          <FormControlLabel 
          value="number" 
          control={
            <Radio sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 14,
              },
              color: 'var(--Yambo_Green_Stroke)',
              '&.Mui-checked': {
                  color: 'var(--Yambo_Green_Stroke)',
                },
            }}
          />} 
          label="Float" 
          />
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, alignItems: 'center' }}>
        <Typography variant="caption">Min</Typography>
        <OutlinedInput
          value={min}
          size="small"
          color="weavy_green_outline"
          onChange={(event) => {
            const value = mode === 'integer' ? parseInt(event.target.value) : parseFloat(event.target.value);
            setMin(isNaN(value) ? 0 : value);
          }}
          onFocus={(e) => e.target.select()}
          sx={{
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0
            },
            '& input[type=number]': {
              MozAppearance: 'textfield'
            }
          }}
        />
        <Typography variant="caption">Max</Typography>
        <OutlinedInput
          value={max}
          size="small"
          color="weavy_green_outline"
          onChange={(event) => {
            const value = mode === 'integer' ? parseInt(event.target.value) : parseFloat(event.target.value);
            setMax(isNaN(value) ? 0 : value);
          }}
          onFocus={(e) => e.target.select()}
          sx={{
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0
            },
            '& input[type=number]': {
              MozAppearance: 'textfield'
            }
          }}
        />
        </Box> 
      </>
      }
      <Box sx={{ mt: 1 }}>
        <Link 
          onClick={()=>setShowMore(!showMore)} 
          sx={{ 
            cursor: role === "guest" || data.isLocked ? 'default' : '',
            pointerEvents: role === "guest" || data.isLocked ? 'none' : '',
          }}
        >
          {showMore ? "Hide" : "+ Show More"}
        </Link>
      </Box>
    </Box>
  );
}

export default NumberCore;
