import React from 'react';
import "./style.css";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

export const MobileUnsupportedPage = () => {
  return (
    <div className="min-h-screen w-full flex items-start justify-center bg-black p-4 font-['Assistant']">
      <div className="w-full max-w-sm">
        <div className="bg-black text-white p-6 space-y-12 text-left">
          <Logo className="logo" />
          <div>
            <h1 className="text-6xl font-normal leading-none tracking-tight">
              Your Masterpiece
            </h1>
            <h1 className="text-6xl font-normal leading-none tracking-tight">
              Needs a Bigger
            </h1>
            <h1 className="text-6xl font-normal leading-none tracking-tight">
              Canvas
            </h1>
          </div>
          <div className="text-left text-gray-400">
            <p className="text-lg font-extralight tracking-wide">
              Catch us on desktop
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
