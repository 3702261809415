import React from "react";
import { DynamicNode2 } from "../VisualNodes";
import { colorMap } from '../../../colors';
import StringCore from "./StringCore";

function StringNode ({ id, data, updateNodeData }) {
  return (
    <DynamicNode2 id={ id } data={ data } className="prompt"  handleColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      <StringCore id={ id } data={ data } updateNodeData={ updateNodeData } />
    </DynamicNode2>
  );
}

export default StringNode;