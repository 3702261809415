import { LoadingButton } from '@mui/lab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography, CircularProgress } from "@mui/material";
import { hasEditingPermissions } from "../Nodes/Utils";

const CancellableLoadingButton = ({
  run,
  onCancel,
  isProcessing,
  predictionStatus,
  data,
  role,
  credits,
  progress,
  canCancel,
}) => {
  const renderStatus = (status) => {
    switch (status) {
      case "starting":
        return "Starting";
      case "processing":
        return `${progress}%`;
      case "initial_processing":
        return "Processing";
      default:
        return "Waiting";
    }
  };

  const renderButton = () => {
    let endIcon;
    let title;
    let subtitle = '';
    let disabled = false;
    if (isProcessing) {
      endIcon = <CircularProgress size="16px" sx={ { color: 'rgba(255, 255, 255, 0.3)' } } />;
      title = renderStatus(predictionStatus);
      disabled = !canCancel;
    } else {
      endIcon = data.result && data.result.length > 0 ? <ReplayIcon /> : <PlayArrowIcon />;
      title = data.result && data.result.length > 0 ? 'Re-run model' : 'Run Model';
      subtitle = data.paid && ` (${data.paid} Credits)`;
      disabled = !hasEditingPermissions(role, data) || (data.paid && credits < data.paid);
    }

    //todo: replace loading button with regular one
    return (
      <LoadingButton
        size="small"
        onClick={ () => isProcessing ? onCancel() : run() }
        endIcon={ endIcon }
        startIcon={ isProcessing && canCancel ? <CancelIcon sx={ { fontSize: '14px !important' } } /> : null }
        variant="contained"
        color="weavy_cta"
        fullWidth
        disabled={ disabled }
        sx={ {
          height: '36px',
        } }
      >
        {title}{subtitle}
      </LoadingButton>
    );
  };

  return renderButton();
};

export default CancellableLoadingButton;
